import axios, { AxiosInstance } from 'axios';

export const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false, // This is the default
    timeout: 60000
});

export const getStatistics = async () => {
    const statistics = await apiClient.post('/announces', { lottoMaster: 5 });
    return statistics;
};

export const getStatisticToday = async () => {
    const statisticToday = await apiClient.post('/announce', { lottoMaster: 5 });
    return statisticToday;
}

export const getPeriods = async (date: string, type: string, lottoMaster: string) => {
    const period = await apiClient.get('/period?announce=' + date + '&type=' + type + '&lottoMaster=' + lottoMaster);
    return period;
}